@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@media (min-width: 751px) {
  #mobilediv {
    display: none;
  }

  #homediv {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  #showreelcontainer {
    position: relative;
    width: 90%;
  }

  #showreelcontainer img {
    width: 100%;
  }

  #iframecontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  #showreel {
    aspect-ratio: 16 / 9 !important;

    width: 90%;
  }

  #squigglyborderWHITE {
    display: none;
  }

  #hometextcontainer {
    display: none;
  }
}

@media (max-width: 750px) {
  #homediv {
    padding-top: 160px;
    min-height: 100vh;

    background-color: #d4b15d;
  }
  #mobilediv {
    display: none;
  }
  #showreelcontainer {
    position: relative;
    width: 100%;
    background-color: white;
    padding-top: 10px;
  }

  #showreelcontainer img {
    width: 100%;
    opacity: 0;
  }

  #iframecontainer {
    position: absolute;
    top: 7px;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  #showreel {
    aspect-ratio: 16 / 9;
    width: 90%;
  }

  #squigglyborderWHITE {
    vertical-align: top;
    height: 30px;
    width: 100%;
  }

  #hometextcontainer {
    /* background-image: linear-gradient(#d4b15d, #a48946); */
    padding-bottom: 2rem;
  }

  .hometext {
    font-family: inter;
    font-weight: 900;
    font-size: 2rem;
    line-height: 1.1;

    text-align: center;
    padding: 0 5% 1rem 5%;
  }
  .goldhometext {
    color: white;
    text-align: left;
  }
  .raisinhometext {
    color: #d4b15d;
    background-color: #282828;

    text-align: right;
  }

  .squigglyborderRAISIN {
    width: 100%;
    height: 30px;

    vertical-align: middle;
  }
  .flipped {
    rotate: 180deg;
    margin-top: -15px;
  }
}
