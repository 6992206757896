* {
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@media (min-width: 751px) {
  #sitecontainer {
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  #contentcontainer {
    margin-left: 300px;
    width: 100%;
    /* background-color: deeppink; */
  }

  #headercontainer {
    position: fixed;
    display: flex;
    flex-direction: row;
  }

  #headerborderdesktop {
    width: 20px;
    height: 100vh;
  }

  #logoandnavcontainer {
    background-color: #282828;
    max-width: 300px;
    min-width: 300px;
    height: 100vh;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
  }

  #mainlogo {
    width: 100%;
    padding: 50px;
  }

  #logoandnavcontainer ul {
    padding-bottom: 1rem;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    text-align: right;
    font-size: 1.75rem;
  }

  #logoandnavcontainer ul li {
    /* background-color: #282828; */
    padding-right: 0.5rem;
    transition: 0.1s;
  }

  #logoandnavcontainer ul li:hover {
    color: #d4b15d;
    cursor: pointer;
    transform: translate(10px);
  }

  #logoandnavcontainer ul li a {
    text-decoration: none;
  }

  .NavLink:link {
    text-decoration: none;
    color: white;
  }

  .NavLink:visited {
    text-decoration: none;
    color: white;
  }

  #logoandnavcontainer ul li .active {
    color: #d4b15d;
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal:hover {
    cursor: pointer;
  }

  .modalframe {
    background-color: white;
    border-style: dashed;
    border-width: 10px;
    border-color: #d4b15d;
  }

  .modalframe:hover {
    cursor: default;
  }

  .modalheader {
    /* height: 50px; */
    text-align: right;
  }

  .modalheader:hover {
    cursor: pointer;
  }

  .modaliframe {
    margin: 30px;
  }

  #headerbordermobile {
    display: none;
  }
}

@media (max-width: 750px) {
  #sitecontainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  #headercontainer {
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  #logoandnavcontainer {
    background-color: #282828;
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #mainlogo {
    height: 100%;
    padding: 10px 10px 0px 25px;
  }

  #logoandnavcontainer ul {
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 1.75rem;
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    /* background-color: blue; */
  }

  #logoandnavcontainer ul li {
    /* background-color: #702929; */
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    transition: 0.1s;
  }

  #logoandnavcontainer ul li a {
    text-decoration: none;
  }

  .NavLink:link {
    text-decoration: none;
    color: white;
  }

  .NavLink:visited {
    text-decoration: none;
    color: white;
  }

  #logoandnavcontainer ul li .active {
    color: #d4b15d;
  }

  #headerbordermobile {
    height: 30px;
    width: 100%;
  }

  #headerborderdesktop {
    display: none;
  }
}
