@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* ------------------------------desktop------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */

@media (min-width: 751px) {
  #ourworkdiv {
    /* background-color: brown; */
    position: relative;
    padding-top: 3rem;
  }

  #ourworkdiv .videodiv {
    position: relative;
    height: 350px;
    width: 606px;
    margin: 0rem auto 3rem auto;
    /* background-color: blue; */
  }

  #ourworkdiv .videoThumbnailDiv {
    position: absolute;
    width: 100%;
    height: 100%;

    transition: 0.8s;
  }

  #ourworkdiv .videodiv:hover .videoThumbnailDiv {
    transform: translateX(-190px);
  }

  #ourworkdiv .videoThumbnailDiv img {
    height: 100%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* opacity: 0.5; */
  }

  #ourworkdiv .videoTitleDiv {
  }

  #ourworkdiv .videoThumbnailDiv .videoTitle:hover {
    cursor: default;
  }

  #ourworkdiv .videodiv:hover .videoTitleDiv {
    opacity: 1;
  }

  #ourworkdiv .videoInfoDiv {
    position: absolute;
    width: 100%;
    height: 100%;

    padding: 2rem 0;

    transition: 1.3s;
  }

  #ourworkdiv .videoInfoContentDiv {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 2.5rem 0 1.5rem 0;
    /* background-color: #fa1212; */
  }

  #ourworkdiv .videoTitle {
    font-family: "inter", sans-serif;
    color: #d8b65f;
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
    max-width: 50%;
  }

  #ourworkdiv .videodiv:hover .videoInfoDiv {
    transform: translateX(300px);
  }

  #ourworkdiv .videoInfoDiv img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    height: 100%;
    z-index: -1;
  }

  #ourworkdiv .videoInfoDiv .videoInfo {
    color: white;
    font-family: inter;
    font-weight: 500;
    max-width: 50%;
  }

  #ourworkdiv .videoInfoDiv .watchbuttonembed {
    text-decoration: none;
    border-style: none;
    border-radius: 0.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    color: white;
    background-color: #a48946;
    padding: 0.5rem 1rem;
  }

  #ourworkdiv .videoInfoDiv .watchbuttonlink {
    display: none;
  }

  #ourworkdiv .videoInfoDiv .watchbuttonembed:hover {
    background-color: #d3bb83;
    cursor: pointer;
  }

  #ourworkdiv #squiggleDiv {
    position: fixed;
    height: 100%;
    width: 100%;
    padding-right: 300px;
    display: flex;
    justify-content: center;
  }

  #ourworkdiv #squiggleDiv img {
    transform: translate(0, -3rem);
    height: 100%;
  }
}

/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------mobile------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */
/* -------------------------------------------------------------------- */

@media (max-width: 750px) {
  #ourworkdiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 175px;
  }

  #ourworkdiv #squiggleDiv img {
    display: none;
  }

  #ourworkdiv .videodiv {
    display: flex;
    flex-direction: column-reverse;

    margin-bottom: 10px;

    width: 95%;
  }

  #ourworkdiv .videoThumbnailDiv {
    position: relative;
    width: 100%;

    /* background-color: pink; */
  }

  #ourworkdiv .videoThumbnailDiv img {
    width: 100%;
  }

  /* #ourworkdiv .videoTitleDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-color: rgba(0, 255, 255, 0.479);
  }

  #ourworkdiv .videoThumbnailDiv .videoTitle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    transform: translate(0, 40%);

    width: 100%;

    font-family: "inter", sans-serif;
    color: #d8b65f;
    font-size: 5rem;
    font-weight: 800;
    text-align: center;
  } */

  #ourworkdiv .videoInfoDiv {
    position: relative;
    width: 100%;

    margin-top: -2rem;

    /* background-color: rgba(0, 255, 255, 0.575); */
  }

  #ourworkdiv .videoInfoDiv img {
    width: 100%;

    z-index: -1;
  }

  #ourworkdiv .videoInfoContentDiv {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 3rem 0 2rem 0;
    /* background-color: #fa1212; */
  }

  #ourworkdiv .videoTitle {
    font-family: "inter", sans-serif;
    color: #d8b65f;
    font-size: 6vw;
    font-weight: 800;
    text-align: center;
    max-width: 80%;
  }

  #ourworkdiv .videoInfoDiv .videoInfo {
    font-family: inter;
    font-weight: 500;
    font-size: 4vw;
    color: white;
    max-width: 80%;
    padding: 0.4rem 0;
  }

  #ourworkdiv .videoInfoDiv .watchbuttonlink {
    text-decoration: none;
    border-style: none;
    border-radius: 0.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    color: white;
    background-color: #a48946;
    padding: 0.5rem 1rem;
  }

  #ourworkdiv .videoInfoDiv .watchbuttonembed {
    display: none;
  }
}
