@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

@media (min-width: 751px) {
  #contactpagecontainer {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #contactdiv {
    /* background-color: rgb(0, 255, 42); */
    /* max-width: 350px; */
    height: 100vh;
    margin-left: 10px;
  }
  #speechbubblecontainer {
    position: relative;
    height: 60%;
    z-index: 2;
  }
  #bubble {
    height: 100%;
    width: 100%;
    /* object-fit: scale-down; */
  }

  #contactinfocontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 10% 7%;

    /* background-color: rgba(165, 42, 42, 0.308); */
  }

  #contactinfo {
    font-family: inter;
    font-weight: 800;
    font-size: 2.8vh;
    line-height: 1.3;

    text-align: center;
    word-break: break-word;
  }

  #avatar {
    height: 40%;
    transform: translateY(-10%) rotate(-5deg);
    box-shadow: 15px 15px #464646;
  }

  #emotionselectiondiv {
    position: fixed;
    bottom: 0;
    right: 0;
    /* background-color: aqua; */
    height: 100%;
    width: 200px;
    padding-bottom: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: column-reverse;
  }

  #emotionselectiondiv p {
    padding: 10px;
    margin: 5px;
    border-radius: 26px;
    text-align: center;

    font-family: inter;
    font-weight: 800;
  }
  #emotionselectiondiv p:hover {
    cursor: pointer;
  }

  #emotionselectiondiv .button {
    background-color: #d4b15d;
  }
  #emotionselectiondiv .button.clicked {
    background-color: #282828;
    color: #d4b15d;
  }

  #emotionselectiondiv .emotionbutton {
    background-color: #d4b15d;
  }
}

@media (max-width: 750px) {
  #contactpagecontainer {
    margin-top: 180px;
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #contactdiv {
    /* background-color: rgb(0, 255, 42); */
    max-width: 450px;
    height: calc(100vh - 180px);
  }
  #speechbubblecontainer {
    position: relative;
    height: 60%;
    z-index: 2;
  }
  #bubble {
    height: 100%;
    width: 100%;
    /* object-fit: scale-down; */
  }

  #contactinfocontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 10% 7%;

    /* background-color: rgba(165, 42, 42, 0.308); */
  }

  #contactinfo {
    font-family: inter;
    font-weight: 800;
    font-size: calc(0.029 * (100vh - 180px));
    line-height: 1.3;

    text-align: center;
    word-break: break-word;
  }

  #avatar {
    height: 40%;
    transform: translateY(-10%) translateX(20%) rotate(-5deg);
    box-shadow: 15px 15px #464646;
  }

  #emotionselectiondiv {
    position: fixed;
    bottom: 0;
    right: 0;
    /* background-color: aqua; */
    height: 100%;
    width: 150px;
    padding-bottom: 10px;
    padding-right: 10px;
    z-index: 2;

    display: flex;
    flex-direction: column-reverse;
  }

  #emotionselectiondiv p {
    padding: 10px;
    margin: 5px;
    border-radius: 26px;
    text-align: center;

    font-family: inter;
    font-weight: 800;
  }
  #emotionselectiondiv p:hover {
    cursor: pointer;
  }

  #emotionselectiondiv .button {
    background-color: #d4b15d;
  }
  #emotionselectiondiv .button.clicked {
    background-color: #282828;
    color: #d4b15d;
  }

  #emotionselectiondiv .emotionbutton {
    background-color: #d4b15d;
  }
}
